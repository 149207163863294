



























































import { Component, Vue } from 'vue-property-decorator'
import Timer from '@/components/common/Timer.vue'
import HighlightText from '@/components/HighlightText.vue'
import Modal from '@/components/common/Modal.vue'

@Component({
  components: {
    Timer,
    HighlightText,
    Modal
  }
})
export default class ScreenOne extends Vue {
  private baseUrl = process.env.BASE_URL
  private timerDurationInSeconds = 20 * 60 // 20sec / 20min
  private isEnd = false
  private isTimerResetConfirmationModalOpen = false
  private isRemainingTimeInformationModalOpen = false
  private timerDurationInSecondsFor10minuteTimer = 10 * 60 // 20sec / 20min

  private unwatchTimerFinished: (() => void) | null = null

  private mounted (): void {
    if ((window.location.host.includes('localhost')) || (window.location.host.includes('d63nen8iwm7oz.cloudfront.net') || window.location.host.includes('dev.innova.bella.health'))) {
      this.timerDurationInSeconds = 20
    }
    this.isEnd = this.$store.getters['timer/finished']
    this.unwatchTimerFinished = this
      .$store
      .watch(
        (state, getters) => getters['timer/finished'],
        (timerFinished) => {
          this.isEnd = timerFinished
        },
        { immediate: true }
      )
    this.$store.dispatch('timer/disableTimerBar')
    this.$store.dispatch('timer/start', { sourceLocation: { name: 'walkthrough 5' }, durationInSeconds: this.timerDurationInSeconds })
    // console.log(this.timerDurationInSeconds)
  }

  private resetTimer ():void {
    this.$store.dispatch('timer/restart')
    this.isTimerResetConfirmationModalOpen = false
  }

  private openSubmitTimeoutInformationModal (): void {
    this.isRemainingTimeInformationModalOpen = true
  }

  private startSubmitTimeoutTimerAndContinue (): void {
    // this.$store.dispatch('timer/enableTimerBar')
    // this.$store.dispatch('timer/restart')
    // this.isTimerResetConfirmationModalOpen = false
    this
      .$store
      .dispatch('walkthrough/startRemainingTimeCountdown')
      .then(() => {
        this.$router.push({ name: 'walkthrough 6' })
      })
  }

  private onlyDesktop (): boolean {
    return window.innerWidth > 767
  }

  private destroyed (): void {
    if (this.unwatchTimerFinished) this.unwatchTimerFinished()
    this.$store.dispatch('timer/enableTimerBar')
  }
}
